import Vue from "vue"
import VueRouter from "vue-router"
import KuMainLayout from "@/layout/ku-main-layout"
// import KuBbsLayout from "@/layout/ku-bbs-layout"
import cmsReload from "./cms-reload"
import { getToken } from "@/utils/auth"
import store from "@/store"
import Kuservice from "@/views/ku-service/ku-service"
import KuPrivate from "@/views/ku-service/ku-private"
import KuCreateCenter from "@/views/ku-creative-center/index"
import KUArticleLayout from "@/views/ku-article/ku-article-layout"
import KuForumLayout from "@/views/ku-forum/ku-forum-layout"
import KuForumContent from "@/views/ku-forum/ku-forum-content"
import { getTopicTypeList } from "@/api/article"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "home-layout",
        component: KuMainLayout,
        redirect: "/index",
        children: [
            {
                path: "index",
                name: "home",
                component: () => import(/* webpackChunkName: "dash" */ "../views/ku-faq-info/index.vue"),
                meta: { title: "校招信息", activeMenu: "/index", needLogin: false },
            },
            {
                path: "myfaq",
                name: "home",
                component: () => import(/* webpackChunkName: "dash" */ "../views/ku-my-faq/index.vue"),
                meta: { title: "我的校招", activeMenu: "/myfaq", needLogin: false },
            },
            {
                path: "findsalary",
                name: "home",
                component: () => import(/* webpackChunkName: "dash" */ "../views/ku-salary/index.vue"),
                meta: { title: "查薪资", activeMenu: "/findsalary", needLogin: true },
            },
            {
                path: "index/:id",
                name: "home-detail",
                component: () => import(/* webpackChunkName: "dash-detail" */ "../views/home-detail.vue"),
                meta: { title: "校招信息", activeMenu: "/index", needLogin: true },
            },
        ],
    },
    {
        path: "/guide",
        name: "home-layout",
        component: KuMainLayout,
        children: [
            {
                path: "",
                name: "guide",
                component: () => import(/* webpackChunkName: "guide" */ "../views/ku-guide.vue"),
                meta: { title: "引导页面", activeMenu: "/guide", needLogin: false },
            },
        ],
    },
    {
        path: "/fullwrite",
        name: "kuWarticlePage",
        component: () =>
            import(/* webpackChunkName: "writearticle" */ "../views/ku-creative-center/ku-warticle-page.vue"),
        meta: { title: "写文章", needLogin: true },
    },
    {
        path: "/bbs",
        name: "bbs",
        component: KuForumLayout,
        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
        children: [
            {
                path: "",
                name: "interview-defatul",
                component: KuForumContent,
                meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: false },
            },
            {
                path: "timeline/100",
                name: "writeArticle",
                component: () => import(/* webpackChunkName: "bbs" */ "../views/ku-article/ku-article-timeline.vue"),
                meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: false },
            },
        ],
    },
    {
        path: "/bbs/detail/:id/:type?",
        name: "article-detail-layout",
        component: KuMainLayout,
        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: false },
        children: [
            {
                path: "",
                name: "article-detail",
                component: () => import(/* webpackChunkName: "bbs" */ "../views/ku-bbs/ku-bbs-detail.vue"),
                meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: false },
            },
        ],
    },
    {
        path: "/create",
        name: "kuCreativeCenter",
        component: KuMainLayout,
        meta: { title: "创作中心", activeMenu: "/create", needLogin: true },
        redirect: "/create/index/articlelist",
        children: [
            {
                path: "index",
                name: "",
                component: KuCreateCenter,
                children: [
                    {
                        path: "writearticle",
                        name: "writeArticle",
                        component: () =>
                            import(
                                /* webpackChunkName: "writearticle" */ "../views/ku-creative-center/ku-write-article.vue"
                            ),
                        meta: { title: "写文章", needLogin: true },
                    },
                    {
                        path: "publishtrend",
                        name: "publishtrend",
                        component: () =>
                            import(
                                /* webpackChunkName: "publishtrend" */ "../views/ku-creative-center/ku-publish-trend.vue"
                            ),
                        meta: { title: "发动态", needLogin: true },
                    },
                    {
                        path: "articlelist",
                        name: "articleList",
                        component: () =>
                            import(
                                /* webpackChunkName: "articlelist" */ "../views/ku-creative-center/ku-article-list.vue"
                            ),
                        meta: { title: "内容管理", needLogin: true },
                        children: [],
                    },
                    {
                        path: "messagelist",
                        name: "messagelist",
                        component: () =>
                            import(
                                /* webpackChunkName: "writearticle" */ "../views/ku-creative-center/ku-message-list.vue"
                            ),
                        meta: { title: "消息管理", needLogin: true },
                        children: [],
                    },
                ],
            },
            {
                path: "messageDetail",
                name: "messageDetail",
                component: () =>
                    import(/* webpackChunkName: "writearticle" */ "../views/ku-creative-center/ku-message-detail.vue"),
                meta: { title: "消息详情", needLogin: true },
            },
        ],
    },
    {
        path: "/info",
        component: KuMainLayout,
        children: [
            // {
            //     path: "/:id",
            //     name: "user-center",
            //     component: () => import(/* webpackChunkName: "user-info" */ "../views/ku-user/ku-user-center.vue"),
            //     meta: { needLogin: false },
            // },
            {
                path: "",
                name: "user-center",
                component: () => import(/* webpackChunkName: "user-info" */ "../views/ku-user/ku-user-center.vue"),
                meta: { needLogin: false },
            },
            {
                path: "edit",
                name: "user-info",
                component: () => import(/* webpackChunkName: "user-info" */ "../views/ku-user/ku-user-info.vue"),
                meta: { needLogin: false },
            },
            {
                path: "validate",
                name: "validate-phone",
                component: () => import(/* webpackChunkName: "user-info" */ "../views/ku-user/ku-validate-phone.vue"),
                meta: { needLogin: false },
            },
            {
                path: "bind",
                name: "bind-phone",
                component: () => import(/* webpackChunkName: "user-info" */ "../views/ku-user/ku-bind-phone.vue"),
                meta: { needLogin: false },
            },
        ],
    },
    {
        path: "/app",
        component: KuMainLayout,
        redirect: "/request/request",
        children: [
            {
                path: "request",
                name: "request-list",
                component: () => import(/* webpackChunkName: "request-list" */ "../views/ku-store/ku-request.vue"),
                meta: { title: "投递管理", activeMenu: "/app/request", needLogin: false },
            },
            {
                path: "store",
                name: "store-list",
                component: () => import(/* webpackChunkName: "store-list" */ "../views/ku-store/ku-store.vue"),
                meta: { title: "投递管理", activeMenu: "/app/request", needLogin: false },
            },
        ],
    },
    {
        path: "/timeline",
        name: "timeline",
        component: KuMainLayout,
        children: [
            {
                path: "",
                name: "timeline-list",
                component: () => import(/* webpackChunkName: "timeline" */ "../views/ku-timeline/ku-timeline.vue"),
            },
        ],
    },
    {
        path: "/pay",
        name: "pay",
        component: KuMainLayout,
        children: [
            {
                path: ":id",
                name: "pay-list",
                component: () => import(/* webpackChunkName: "pay" */ "../views/ku-ship/ku-ship-pay.vue"),
            },
        ],
    },
    {
        path: "/service",
        component: Kuservice,
        meta: { title: "服务条款" },
        hidden: true,
    },
    {
        path: "/private",
        component: KuPrivate,
        meta: { title: "隐私协议" },
        hidden: true,
    },
    {
        path: "/reload",
        component: cmsReload,
        meta: { title: "reload", activeMenu: "/" },
        hidden: true,
    },
    {
        path: "*",
        component: KuMainLayout,
        redirect: "/index",
        children: [
            {
                path: "/ship/:id/:serviceId",
                name: "service-detail",
                component: () => import(/* webpackChunkName: "ship-deatil" */ "../views/ku-ship/ku-ship-service.vue"),
                meta: { title: "找学长", activeMenu: "/ship", needLogin: false },
            },
        ],
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})
let hadAddBBSRoute = false

function createBbsRoute() {
    if (hadAddBBSRoute) {
        return Promise.resolve()
    }
    return getTopicTypeList().then(resp => {
        const result = (resp.data || [])
            .map(item => {
                const { topicType } = item
                if (topicType == 999) {
                    return {
                        path: `all/:id`,
                        name: `all-content`,
                        component: KuForumContent,
                        meta: { title: "论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 101) {
                    return {
                        path: `action/:id`,
                        name: `action-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 6) {
                    return {
                        path: `refuge/:id`,
                        name: `refuge-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 0) {
                    return {
                        path: `interview/:id`,
                        name: `interview-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 1) {
                    return {
                        path: `study/:id`,
                        name: `study-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 2) {
                    return {
                        path: `tech/:id`,
                        name: `tech-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 3) {
                    return {
                        path: `daily/:id`,
                        name: `daily-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 4) {
                    return {
                        path: `knowledge/:id`,
                        name: `knowledge-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 5) {
                    return {
                        path: `more/:id`,
                        name: `more-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 98) {
                    return {
                        path: `faq/:id`,
                        name: `faq-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 97) {
                    return {
                        path: `learn/:id`,
                        name: `learn-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 96) {
                    return {
                        path: `recommend/:id`,
                        name: `recommend-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 99) {
                    return {
                        path: `store/:id`,
                        name: `store-content`,
                        component: KuForumContent,
                        meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: true },
                    }
                }
                if (topicType == 8) {
                    return {
                        path: `resume/:id`,
                        name: `store-resume`,
                        component: KuForumContent,
                        meta: { title: "简历", activeMenu: "/bbs/all/999", needLogin: false },
                    }
                }
                if (topicType == 10) {
                    return {
                        path: `recommend/:id`,
                        name: `store-recommend`,
                        component: KuForumContent,
                        meta: { title: "内推", activeMenu: "/bbs/all/999", needLogin: false },
                    }
                }
                if (topicType == 14) {
                    return {
                        path: `salary/:id`,
                        name: `store-salary`,
                        component: KuForumContent,
                        meta: { title: "薪资爆料", activeMenu: "/bbs/all/999", needLogin: false },
                    }
                }
                if (topicType == 100) {
                    return null
                    // return {
                    //     path: `timeline/:id`,
                    //     name: `timeline-content`,
                    //     component: KuForumContent,
                    //     meta: { title: "校招论坛", activeMenu: "/bbs/all/999", needLogin: false },
                    // }
                }
                return null
            })
            .filter(item => item)
        const routes = router.getRoutes()
        result.map(item => {
            const { name } = item
            if (isInArray(routes, name) == -1) {
                router.addRoute("bbs", { ...item })
            }
        })
        hadAddBBSRoute = true
        function isInArray(array, attr) {
            for (let i = 0, len = array.length; i < len; i++) {
                const { name } = array[i]
                if (name == attr) {
                    return i
                }
            }
            return -1
        }
    })
}

router.beforeEach((to, from, next) => {
    const { meta, redirectedFrom } = to
    createBbsRoute().then(() => {
        if (getToken()) {
            const userInfo = store.getters && store.getters.userInfo
            const hasUserInfo = Object.keys(userInfo).length > 0
            if (!hasUserInfo) {
                store
                    .dispatch("GetInfo")
                    .then(() => {
                        if ((redirectedFrom + "").indexOf("/index") > -1) {
                            next({ ...to, replace: true })
                        } else {
                            next({ path: redirectedFrom, replace: true })
                        }
                        // if ((redirectedFrom + "").indexOf("/bbs") > -1) {
                        //     next({ path: redirectedFrom, replace: true })
                        // } else {
                        //     next({ ...to, replace: true })
                        // }
                    })
                    .catch(() => { })
            } else {
                if ((redirectedFrom + "").indexOf("/bbs") > -1) {
                    next({ path: redirectedFrom, replace: true })
                } else {
                    next()
                }
            }
        } else {
            // 如果不是比特正价学员则跳转至教务系统
            if (to.path == '/index' && to.query.code) {
                next()
            } else {
                window.location.href = process.env.VUE_APP_EDU
            }
        }
    })
})

export default router
