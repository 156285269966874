import request from "@/utils/request"

// 模糊搜索学校信息
export function searchSchool(searchValue) {
    return request({
        url: "/friend/school/search",
        method: "get",
        params: { searchValue },
    })
}

// 模糊搜索专业
export function searchMajor(searchValue) {
    return request({
        url: "/friend/major/search",
        method: "get",
        params: { searchValue },
    })
}

// 保存学校和专业信息
export function saveCompany(params) {
    return request({
        url: "/friend/user/saveOrganize",
        method: "post",
        data: { ...params },
    })
}

// 模糊搜索专业
export function getCompDetailv1(companyId) {
    return request({
        url: "/friend/company/semiLogin/detail",
        method: "get",
        params: { companyId },
    })
}

// 模糊搜索专业
export function getCompDetail(companyId) {
    return request({
        url: "/friend/company/semiLogin/detailV2",
        method: "get",
        params: { companyId },
    })
}

export function getRequestStoreStatus(companyId) {
    return request({
        url: "/friend/company/mark/status",
        method: "get",
        params: { companyId },
    })
}

export function getTotalData() {
    return request({
        url: "/friend/company/mark/count",
        method: "get",
        params: {},
    })
}

// 校友列表
export function getFdList(params) {
    return request({
        url: "/friend/alumni/semiLogin/list",
        method: "get",
        params: { ...params },
    })
}

// 咨询校友
export function askFd(alumniId) {
    return request({
        url: "/friend/alumni/advice/save",
        method: "post",
        data: { alumniId },
    })
}

// 校友在职列表
export function getDashFdList(params) {
    return request({
        url: "/friend/company/semiLogin/alumni/list",
        method: "get",
        params: { ...params },
    })
}

// 精品推荐列表
export function getDashHotList(params) {
    return request({
        url: "/friend/company/semiLogin/recommend/list",
        method: "get",
        params: { ...params },
    })
}

// 招聘公司列表
export function getCompanyList(params) {
    return request({
        url: "/friend/recruitment/semiLogin/company/list",
        method: "get",
        params: { ...params },
    })
}

// 今日开启校招公司列表
export function startTodayCompanyList(params) {
    return request({
        url: "/friend/recruitment/semiLogin/company/today/list",
        method: "get",
        params: { ...params },
    })
}

// 添加到不感兴趣
export function uninterest(companyId) {
    return request({
        url: "/friend/user/company/uninterest/add",
        method: "post",
        data: { companyId },
    })
}

// 开启校招
export function startRecruitment() {
    return request({
        url: "/friend/user/recruitment/start",
        method: "post",
        // data: { companyId },
    })
}

// 根据公司名称模糊搜索
export function searchCompanyByName(searchValue, cityId = "") {
    return request({
        url: "/friend/recruitment/semiLogin/company/search",
        method: "get",
        params: { searchValue, cityId },
    })
}

// 预投递
export function prepost(companyId) {
    return request({
        url: "/friend/user/recruitment/prepost",
        method: "post",
        data: { companyId },
    })
}

// 投递数量汇总
export function recruitmentDetail(params) {
    return request({
        url: "/friend/user/recruitment/detail",
        method: "get",
        params: { ...params },
    })
}

// export function postOfferList(params) {
//     return request({
//         url: "/friend/user/recruitment/list",
//         method: "get",
//         params: { ...params },
//     })
// }

export function postOfferList(params) {
    return request({
        url: "/friend/user/recruitment/pcList",
        method: "get",
        params: { ...params },
    })
}
// 收藏列表
export function interestList(params = {}) {
    return request({
        url: "/friend/user/company/interest/list",
        method: "get",
        params,
    })
}
// 不感兴趣列表
export function getNotcareList(params = {}) {
    return request({
        url: "/friend/user/company/uninterest/list",
        method: "get",
        params
    })
}
// 重新关注
export function removeNotCareItem(data = {}) {
    return request({
        url: "/friend/user/company/uninterest/remove",
        method: "post",
        data
    })
}
// 新增或编辑岗位
export function postSave(params) {
    return request({
        url: "/friend/user/company/post/save",
        method: "post",
        data: { ...params },
    })
}

// 修改备注
export function updateRemark(params) {
    return request({
        url: "/friend/user/company/post/updateRemark",
        method: "post",
        data: { ...params },
    })
}

// 修改备注
export function cancelPrepost(params) {
    return request({
        url: "/friend/user/recruitment/prepost/cancel",
        method: "post",
        data: { ...params },
    })
}

export function offerTimeline(params) {
    return request({
        url: "/friend/user/recruitment/process/list",
        method: "get",
        params: { ...params },
    })
}

// 精品推荐列表 needtoken
export function getDashHotListByToken(params) {
    return request({
        url: "/friend/company/recommend/post/list",
        method: "get",
        params: { ...params },
    })
}

export function getDetailCompany(alumniId) {
    return request({
        url: "/friend/alumni/semiLogin/detail",
        method: "get",
        params: { alumniId },
    })
}

// 获取校友提供的服务列表
export function getServiceList(alumniId) {
    return request({
        url: "/friend/alumni/semiLogin/service/list",
        method: "get",
        params: { alumniId },
    })
}

// 校友服务详情
export function getServiceDetail(alumniServiceId) {
    return request({
        url: "/friend/alumni/semiLogin/service/detail",
        method: "get",
        params: { alumniServiceId },
    })
}

// 提交订单
export function sendOrderItem(params) {
    return request({
        url: "/friend/order/job/add",
        method: "post",
        data: { ...params },
    })
}

// 提交订单
export function sendOrderItemByPay(params) {
    return request({
        url: "/friend/order/job/addV2",
        method: "post",
        data: { ...params },
    })
}

// 获取订单的信息
export function getOrderInfo(params) {
    return request({
        url: "/friend/order/job/prepay",
        method: "post",
        data: { ...params },
    })
}

// 查看订单支付状态
export function checkOderStatus(outTradeNo) {
    return request({
        url: "/friend/pay/status",
        method: "get",
        params: { outTradeNo },
    })
}

// 薪资雷达接口
export function getPayrollList(params) {
    return request({
        // url: "/friend/salary/semiLogin/list",
        url:"/friend/salary/pc/semiLogin/list",
        method: "get",
        params: { ...params },
    })
}

//
// 获取简历接口
export function getResumeList(params) {
    return request({
        url: "/friend/user/resume/list",
        method: "get",
        params: { ...params },
    })
}

// 简历删除
export function deleteResume(params) {
    return request({
        url: "/friend/user/resume/delete",
        method: "post",
        data: { ...params },
    })
}

// 获取值班学长
export function getAlumniRandom(params) {
    return request({
        url: "/friend/alumni/random",
        method: "get",
        params: { ...params },
    })
}

// 滚动展示文章top列表
export function getTopArticle(params) {
    return request({
        url: "/friend/article/roll/top-list",
        method: "get",
        params: { ...params },
    })
}

// 内推岗位列表名称
export function getRecommendNameList(params) {
    return request({
        url: "/friend/user/recommend/post/name/list",
        method: "get",
        params: { ...params },
    })
}

// 获取我的内推
export function getPostList(params) {
    return request({
        url: "/friend/user/recommend/post/list",
        method: "get",
        params: { ...params },
    })
}

// 内推岗位列表
export function getRecommendList(params) {
    return request({
        url: "/friend/company/recommend/post/semiLogin/list",
        method: "get",
        params: { ...params },
    })
}

// 公司内推岗位列表
export function getRecommendListByCompanyId(companyId) {
    return request({
        url: "/friend/company/recommend/post/semiLogin/listByCompanyId",
        method: "get",
        params: { companyId },
    })
}

// 内推岗位详情
export function getRecommendDeatil(companyRecommendPostId) {
    return request({
        url: "/friend/company/recommend/post/semiLogin/detail",
        method: "get",
        params: { companyRecommendPostId },
    })
}

// /friend/user/resume/update
// 获取订单的信息
export function updateResumeName(params) {
    return request({
        url: "/friend/user/resume/update",
        method: "post",
        data: { ...params },
    })
}
