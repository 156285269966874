import axios from "axios"
import { Message } from "element-ui"
import store from "@/store"
import { getToken } from "@/utils/auth"
import router from "@/router"
import hmacSHA256 from "crypto-js/hmac-sha256.js"
import Base64 from "crypto-js/enc-base64.js"

const errorCode = {
    401: "认证失败，无法访问系统资源",
    403: "当前操作没有权限",
    404: "访问资源不存在",
    500101: "订单已经支付",
    500102: "没有找到订单",
    default: "系统未知错误，请反馈给管理员",
}
// 是否显示重新登录
export let isRelogin = { show: false }

export function tansParams(params) {
    let result = ""
    for (const propName of Object.keys(params)) {
        const value = params[propName]
        var part = encodeURIComponent(propName) + "="
        if (value !== null && value !== "" && typeof value !== "undefined") {
            if (typeof value === "object") {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && value !== "" && typeof value[key] !== "undefined") {
                        let params = propName + "[" + key + "]"
                        var subPart = encodeURIComponent(params) + "="
                        result += subPart + encodeURIComponent(value[key]) + "&"
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&"
            }
        }
    }
    return result
}

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8"
// 创建axios实例
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 300000,
})

const value = "09247ec02edce69f6a2d"
function getRandomStr(len = 16) {
    const _charStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    function RandomIndex(min, max, i) {
        let index = Math.floor(Math.random() * (max - min + 1) + min),
            numStart = _charStr.length - 10
        if (i == 0 && index >= numStart) {
            index = RandomIndex(min, max, i)
        }
        return index
    }

    function getRandomString(len = 16) {
        let min = 0,
            _str = "",
            max = _charStr.length - 1
        for (var i = 0, index; i < len; i++) {
            index = RandomIndex(min, max, i)
            _str += _charStr[index]
        }
        return _str
    }
    return getRandomString(len)
}

function createSign(params = {}, method) {
    const signData = {
        "bit-secretId": "BIT_AZ0tHd", //
        "bit-nonce": getRandomStr(), // 随机字符串
        "bit-timestamp": Date.now(), // 当前时间戳
    }
    let temp = { ...signData }
    if (method == "get") {
        temp = { ...params, ...temp }
    } else {
        const bodyStr = JSON.stringify(params) || ""
        temp = { bodyStr, ...temp }
        signData["bodyStr"] = bodyStr
    }
    const sorted = Object.keys(temp).sort()
    const sortedValue = sorted.map(key => temp[key]).join("") + value
    const sign = Base64.stringify(hmacSHA256(sortedValue, value)).toUpperCase()
    signData["bit-sign"] = sign
    return signData
}

// request拦截器
service.interceptors.request.use(
    config => {
        const isUpload = (config.headers || {})["Content-Type"] == "multipart/form-data"
        const isToken = (config.headers || {}).isToken === false
        if (getToken() && !isToken) {
            config.headers["Authorization"] = "Bearer " + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        if (!isUpload) {
            const { bodyStr, ...rest } = createSign(config.method == "get" ? config.params : config.data, config.method)
            config.headers = { ...config.headers, ...rest }
            // get请求映射params参数
            if (config.method === "get" && config.params) {
                let url = config.url + "?" + tansParams(config.params)
                url = url.slice(0, -1)
                config.params = {}
                config.url = url
            } else {
                config.data = { bodyStr: bodyStr }
            }
        }
        const baseURL = (config.headers || {}).baseURL
        if (baseURL) {
            config.baseURL = baseURL
        }
        return config
    },
    error => {
        Promise.reject(error)
    },
)

// 响应拦截器
service.interceptors.response.use(
    res => {
        // 如果触发了点赞、收藏、评论等接口重新获取消息数
        let msgWithInterface = [
            "/friend/user/message/comment/delete",
            "/friend/user/message/like/delete",
            "/friend/user/message/msg/delete",
        ]
        store.dispatch("setIsHaveMessage", false)
        if (msgWithInterface.indexOf(res.config.url) !== -1) {
            setTimeout(() => {
                store.dispatch("setIsHaveMessage", true)
            }, 100)
        }
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200
        // 获取错误信息
        const msg = errorCode[code] || res.data.msg || errorCode["default"]
        // 二进制数据则直接返回
        if (res.request.responseType === "blob" || res.request.responseType === "arraybuffer") {
            return res.data
        }
        if (code == 401) {
            store
                .dispatch("FedLogOut")
                .then(res=>{
                    // 401的情况让其登录教务系统后重新授权
                    window.location.href = process.env.VUE_APP_EDU
                })
                // .then(() => {
                //     isRelogin.show = false
                //     store.commit("SET_SHOWLOGIN", true)
                // })
                // .then(() => {
                //     router.push("/ship")
                // })
            return Promise.reject("无效的会话，或者会话已过期，请重新登录。")
        } else if (code == 500101) {
            // 订单已经支付
            Message({
                message: msg,
                type: "success",
            })
            return Promise.reject({ code: 500101, msg })
        } else if (code == 500102) {
            // 订单已经支付
            Message({
                message: msg,
                type: "error",
            })
            return Promise.reject({ code: 500102, msg })
        } else if (code == 500) {
            Message({
                message: msg,
                type: "error",
            })
            return Promise.reject(new Error(msg))
        } else if (code !== 200) {
            // Notification.error({
            //     title: msg,
            // })
            return Promise.reject("error")
        } else {
            return res.data
        }
    },
    error => {
        let { message } = error
        if (message == "Network Error") {
            message = "后端接口连接异常"
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时"
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常"
        }
        return Promise.reject(error)
    },
)

export default service
