<template>
    <div class="ku-bbs-card-section">
        <div class="ku-bbs-card" v-for="item in list" :key="item.topicId">
            <img v-if="isTop(item)" class="zhiding-hot" src="../../assets/icons/top.svg" />
            <div class="ku-bbs-card-header button" @click="go2Detail(item)">
                <img :src="item.headImage || defaultUrl" @click.stop="go2UserCenter(item)" />
                <div class="ku-bbs-card-info">
                    <div class="ku-bbs-card-name" @click.stop="go2UserCenter(item)">
                        <img class="icon" src="@/assets/icons/renzheng.svg" v-show="item.pushUserIdentity == 1" />
                        <div>{{ item.publishName }}</div>
                    </div>
                    <div class="ku-bbs-card-time">
                        {{ item.createTime ? getCreateTime(item.createTime) : "--" }}
                    </div>
                </div>
                <!-- <div class="float-right" v-if="item.topicId === '1739502612883427330' &&( +$route.params.id!==99)">
                    <div class="top-box">
                        <span class="red">顶</span>
                        <span class="black">置顶</span>
                    </div>
                </div> -->
            </div>
            <div class="ku-bbs-card-title" @click="go2Detail(item)">
                <span v-html="item.title"></span>
            </div>
            <div class="ku-bbs-card-content-wrapper" @click="go2Detail(item)">
                <div class="ku-bbs-card-content-text" style="width: 550px"
                    v-if="(item.imageList && item.imageList.length > 0) || item.briefContent" v-html="item.content||item.briefContent">
                </div>
                <div class="ku-bbs-card-content-text" style="width: 550px"
                    v-if="!item.briefContent && (!item.imageList || item.imageList.length == 0)">
                    内容已被作者下架
                </div>
                <div class="ku-bbs-card-content-more"
                    v-if="(item.imageList && item.imageList.length > 0) || item.briefContent">
                    <span class="ku-bbs-card-content-more-text">阅读全文</span>
                </div>
            </div>
            <div class="ku-bbs-card-image" v-if="item.imageList && item.imageList.length > 0">
                <div class="ku-bbs-card-image-item" v-for="(url, index) in getImageList(item.imageList)" :key="url">
                    <ku-image style="width: 100px; height: 100px; border-radius: 8px" :src="url"
                        :is-show-more="index == 5 && item.imageList.length - 6 > 0"
                        :preview-src-list="item.imageList"></ku-image>
                </div>
            </div>
            <div class="ku-bbs-card-footer button">
                <div class="ku-bbs-card-footer-item"
                    v-if="(item.imageList && item.imageList.length > 0) || item.briefContent">
                    <i class="ku-bbs-card-footer-icon ku-bbs-card-view"></i>
                    <span class="ku-bbs-card-footer-item-text ku-bbs-card-footer-item-text-eye">
                        {{ $num(item.viewCount) }}
                    </span>
                </div>
                <div class="ku-bbs-card-footer-item"
                    v-if="(item.imageList && item.imageList.length > 0) || item.briefContent"
                    @click="zanClickHandler(item)">
                    <i class="ku-bbs-card-footer-icon ku-bbs-card-zan" :class="{ 'ku-bbs-card-zan-green': item.like }"></i>
                    <span class="ku-bbs-card-footer-item-text">
                        {{ item.likeCount ? $num(item.likeCount) : "点赞" }}
                    </span>
                </div>
                <div class="ku-bbs-card-footer-item" @click="storeClickHandler(item)">
                    <i class="ku-bbs-card-footer-icon ku-bbs-card-store"
                        :class="{ 'ku-bbs-card-store-green': item.collect }"></i>
                    <span class="ku-bbs-card-footer-item-text">
                        {{ item.collectCount ? $num(item.collectCount) : "收藏" }}
                    </span>
                </div>
                <div class="ku-bbs-card-footer-item"
                    v-if="(item.imageList && item.imageList.length > 0) || item.briefContent" @click="go2Detail(item)">
                    <i class="ku-bbs-card-footer-icon ku-bbs-card-comment"
                        :class="{ 'ku-bbs-card-comment-green': item.commentCount }"></i>
                    <span class="ku-bbs-card-footer-item-text">
                        {{ item.commentCount ? $num(item.commentCount) : "评论" }}
                    </span>
                </div>
            </div>
            <div class="ku-bbs-card-ship" v-for="sItem in item.answers" @click="go2Detail(item)">
                <div class="ku-bbs-card-ship-header">学长解题</div>
                <div class="ku-bbs-card-ship-body">
                    <div class="ku-bbs-card-ship-infos" @click.stop="go2UserCenter(sItem)">
                        <img :src="sItem.publishHeadImage || defaultUrl" />
                        <div class="ku-bbs-card-ship-infos-body">
                            <div class="ku-bbs-card-ship-infos-name">{{ sItem.publishName || "" }}</div>
                            <div class="ku-bbs-card-ship-infos-datas">{{ renderInfos(sItem) }}</div>
                        </div>
                    </div>
                    <div class="ku-bbs-card-ship-text">
                        <div class="ku-bbs-card-ship-clamp">
                            <div v-html="sItem.content"></div>
                        </div>
                    </div>
                    <div class="ku-bbs-card-image ship-mg-20" v-if="sItem.imageList && sItem.imageList.length > 0">
                        <div class="ku-bbs-card-image-item" v-for="(url, index) in getImageList(sItem.imageList)"
                            :key="url">
                            <ku-image style="width: 100px; height: 100px; border-radius: 8px" :src="url"
                                :is-show-more="index == 5 && sItem.imageList.length - 6 > 0"
                                :preview-src-list="sItem.imageList"></ku-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="work-detail-empty" v-if="isEmpty">
            <img class="work-detail-empty-img" src="@/assets/nodata/queshengye.png" />
            <div class="work-detail-empty-text">{{ emptyText }}</div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import KuImage from "./ku-image"
import { addArticleCollect, removeArticleCollect, addArticleLike, removeArticleLike } from "@/api/article"
import { formatDate } from "element-ui/src/utils/date-util"
export default {
    name: "ku-bbs-card",
    components: { KuImage },
    props: {
        postItem: {
            type: Array,
            default() {
                return []
            },
        },
    },
    data() {
        const list = this.postItem
        return {
            list: list,
            emptyText: "数据加载中...",
            queue: [],
            defaultUrl: "/img/defaultphoto.9c2a4db9.png",
            isLoading: false
        }
    },
    created() {
        console.log(this.$route.params.id)
    },
    watch: {
        postItem(val) {
            this.list = val || []
        },
        token(val) {
            if (val) {
                // this.getArticleNumFun()
                while (this.queue.length) {
                    const callback = this.queue.shift()
                    callback()
                }
            }
        },
    },
    computed: {
        ...mapGetters(["token"]),
        isEmpty() {
            return this.list.length == 0 && this.total == 0
        },
    },
    methods: {
        renderInfos(sItem) {
            const { pushUserIdentity, companyName, postName, schoolName } = sItem || {};
            if (pushUserIdentity == 1) {
                return `${companyName || ""}${postName || ""}`
            } else {
                return schoolName || ""
            }
        },
        go2UserCenter(item) {
            // const route = this.$router.resolve(`/info?id=${item.userId}`)
            // window.open(route.href)
        },
        getCreateTime(time) {
            const cTime = new Date(time)
            const nTime = new Date()
            const cy = cTime.getFullYear()
            const ny = nTime.getFullYear()
            if (ny - cy >= 1) {
                // 大于1年
                return formatDate(cTime, "yyyy-MM-dd HH:mm")
            }
            const isToday =
                new Date(nTime.getFullYear(), nTime.getMonth(), nTime.getDate()).getTime() -
                new Date(cTime.getFullYear(), cTime.getMonth(), cTime.getDate()).getTime() ==
                0
            const isLast =
                new Date(nTime.getFullYear(), nTime.getMonth(), nTime.getDate() - 1).getTime() -
                new Date(cTime.getFullYear(), cTime.getMonth(), cTime.getDate()).getTime() ==
                0
            if (isToday) {
                return `今天 ${formatDate(cTime, "HH:mm")}`
            } else if (isLast) {
                return `昨天 ${formatDate(cTime, "HH:mm")}`
            } else {
                return formatDate(cTime, "MM-dd HH:mm")
            }
        },
        isTop(item){
            // console.log(this.$route.params.id)
            return ((item.imageList && item.imageList.length > 0) || item.briefContent)&&item.topRank&&(this.$route.params.id!=='99')
        },
        go2Detail(row) {
            if (row.briefContent || (row.imageList && row.imageList.length > 0)) {
                this.$emit("go-detail", row)
            } else {
                this.$message.error("当前内容已被作者下架！")
            }
        },
        getImageList(imgs) {
            if (!imgs) {
                return []
            }
            return imgs.slice(0, 6)
        },
        storeClickHandler(row) {
            const { collectCount, collect, topicId } = row
            const {path} = this.$route;
            if (!this.token) {
                this.queue.push(() => {
                    addArticleCollect(topicId).then(() => {
                        this.$router.push({
                            path: "/reload",
                            query: {
                                redirect: path,
                            },
                        })
                    })
                })
                return this.$store.commit("SET_SHOWLOGIN", true)
            }
            if (this.isLoading) {
                return
            }
            if (collect) {
                this.isLoading = true;
                return removeArticleCollect(topicId).then(() => {
                    row.collectCount = collectCount - 1
                    row.collect = false
                    this.$emit("do-remove", row)
                    this.isLoading = false
                }).catch(() => {
                    this.isLoading = false;
                })
            } else {
                this.isLoading = true;
                return addArticleCollect(topicId).then(() => {
                    row.collectCount = collectCount + 1
                    row.collect = true
                    this.isLoading = false;
                }).catch(() => {
                    this.isLoading = false;
                })
            }
        },
        zanClickHandler(row) {
            const { likeCount, like, topicId } = row
            const {path} = this.$route;
            if (!this.token) {
                this.queue.push(() => {
                    addArticleLike(topicId).then(() => {
                        this.$router.push({
                            path: "/reload",
                            query: {
                                redirect: path,
                            },
                        })
                    })
                })
                return this.$store.commit("SET_SHOWLOGIN", true)
            }
            if (this.isLoading) {
                return;
            }
            if (like) {
                this.isLoading = true;
                return removeArticleLike(topicId).then(() => {
                    row.likeCount = likeCount - 1
                    row.like = false
                    this.isLoading = false;
                }).catch(() => {
                    this.isLoading = false;
                })
            } else {
                this.isLoading = true;
                return addArticleLike(topicId).then(() => {
                    row.likeCount = likeCount + 1
                    row.like = true
                    this.isLoading = false;
                }).catch(() => {
                    this.isLoading = false;
                })
            }
        },
    },
}
</script>
<style>
.ku-bbs-card-content-text  img{
    display: none;
}
</style>
<style lang="scss" scoped>
.ship-mg-20 {
    margin-top: 10px;
}

.ku-bbs-card-ship {
    padding: 20px;
    background: #F8F8F8;
    border-radius: 5px;
    margin-top: 11px;
    cursor: pointer;

    .ku-bbs-card-ship-header {
        font-size: 16px;
        font-weight: 600;
        color: #000000;
        padding-bottom: 10px;
        border-bottom: 1px solid #DEDEDE;
        margin-bottom: 15px;
    }

    .ku-bbs-card-ship-infos {
        display: flex;
        height: 40px;
        align-items: center;
        margin-bottom: 14px;

        img {
            width: 40px;
            height: 40px;
            border-radius: 40px;
        }
    }

    .ku-bbs-card-ship-infos-body {
        margin-left: 10px;
    }

    .ku-bbs-card-ship-infos-name {
        font-size: 14px;
        font-weight: 600;
        color: #222222;
    }

    .ku-bbs-card-ship-infos-datas {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
    }

    .ku-bbs-card-ship-text {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
    }

    .ku-bbs-card-ship-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space: pre-wrap;
    }
}

.ku-bbs-card-section {
    margin-left: 20px;
}

.ku-bbs-card {
    padding: 20px 24px 20px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;


    .ku-bbs-card-image {
        display: flex;
        margin-bottom: 20px;
    }

    .ku-bbs-card-image-item {
        width: 94px;
        height: 94px;
        margin-right: 10px;
        cursor: pointer;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .ku-bbs-card-header {
        overflow: hidden;
        cursor: pointer;
        position: relative;

        .float-right {
            display: flex;
            align-items: center;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;

            .top-box {
                width: 70px;
                height: 30px;
                line-height: initial;
                display: flex;
                border-radius: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #f7f7f7;
                flex-wrap: nowrap;
                font-size: 14px;
            }

            .red {
                display: flex;
                justify-content: center;
                align-items: center;
                background: #FF6700;
                color: #fff;
                font-weight: bold;
                width: 12px;
                height: 12px;
                border-radius: 4px;
                font-size: 12px;
                padding: 3px;
                padding-top: 2px;
                margin-right: 4px;
            }
        }

        img {
            width: 40px;
            height: 40px;
            float: left;
            margin-right: 10px;
            border-radius: 40px;
            overflow: hidden;
            object-fit: contain;
        }
    }

    .ku-bbs-card-info {
        overflow: hidden;
    }

    .ku-bbs-card-name {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #222222;

        .icon {
            width: 16px;
            height: 16px;
            margin-right: 5px;
        }
    }

    .ku-bbs-card-time {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        margin-top: 6px;
    }

    .ku-bbs-card-title {
        font-size: 16px;
        font-weight: 600;
        color: #222222;
        margin: 14px 0 7px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .ku-bbs-card-content {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        max-width: 600px;
    }

    .ku-bbs-card-content-wrapper {
        margin-bottom: 10px;
        cursor: pointer;
        overflow: hidden;
    }

    .ku-bbs-card-content-text {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        color: #000 !important;
        img{
            display: none !important;
        }
        .highlight-key{
            color: #f53f3f !important;
        }

    }

    .ku-bbs-card-content-more {
        text-align: right;
        height: 0;
        position: relative;
    }

    .ku-bbs-card-content-more-text {
        position: absolute;
        font-size: 14px;
        color: #24c68b;
        margin-top: -20px;
        right: 0;
        display: inline-block;
        white-space: nowrap;
        background: #fff;
        cursor: pointer;
    }

    .ku-bbs-card-footer {
        display: flex;
    }

    .ku-bbs-card-footer-item {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 40px;
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        cursor: pointer;
    }

    .ku-bbs-card-footer-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 2px;
        vertical-align: top;

        img {
            width: 100%;
            height: 100%;
        }

        .ku-bbs-card-footer-item-text {
            line-height: 20px;
        }
    }
}

.ku-bbs-card+.ku-bbs-card {
    margin-top: 10px;
}

.ku-bbs-card-footer-item-text {
    font-size: 12px;
}

.ku-bbs-card-zan {
    margin-top: -4px;
    background: url("@/assets/icons/dianzan.svg") center center no-repeat;
    background-size: 100% 100%;
}

.ku-bbs-card-zan-green {
    margin-top: -4px;
    background: url("@/assets/icons/dianzan-green.svg") center center no-repeat;
    background-size: 100% 100%;
}

.ku-bbs-card-store {
    margin-top: -4px;
    background: url("@/assets/icons/shoucang.svg") center center no-repeat;
    background-size: 100% 100%;
}

.ku-bbs-card-store-green {
    margin-top: -4px;
    background: url("@/assets/icons/shoucang-green.svg") center center no-repeat;
    background-size: 100% 100%;
}

.ku-bbs-card-comment {
    background: url("@/assets/icons/pinglun.svg") center center no-repeat;
    background-size: 100% 100%;
}

.ku-bbs-card-comment-green {
    background: url("@/assets/icons/pinglun.svg") center center no-repeat;
    background-size: 100% 100%;
}

.ku-bbs-card-share {
    background: url("@/assets/bbs/zhuanfa.png") center center no-repeat;
    background-size: 100% 100%;
}

.ku-bbs-card-view {
    background: url("@/assets/icons/liulan.svg") center center no-repeat;
    background-size: 100% 100%;
}

.ku-bbs-card-more {
    background: url("@/assets/bbs/gengduo.png") center center no-repeat;
    background-size: 100% 100%;
}
</style>
