<template>
    <div class="ku-navbar">
        <el-row :gutter="0">
            <el-col :span="17">
                <div class="ku-navbar-menus button">
                    <!-- <img class="ku-navbar-logo" src="@/assets/icons/kuxuezhanglogo.png" @click="goIndex" /> -->
                    <img class="ku-navbar-logo" src="@/assets/logo.png" @click="goIndex"  alt="" srcset="">
                    <!-- <div class="write-menu" v-if="isWrite">
                        <span class="fir-title" @click="goCreate">创作中心</span>
                        <span><i class="el-icon-arrow-left"></i>写文章</span>
                    </div> -->
                    <el-menu
                        :router="true"
                        active-text-color="#24C68B"
                        text-color="#fff"
                        background-color="transparent"
                        :default-active="activeMenu"
                        class="ku-navbar-menu"
                        mode="horizontal"
                    >
                        <el-menu-item index="/index" v-track-event.click="'顶部导航,点击,校招信息'">校招信息</el-menu-item>
                        <el-menu-item index="/myfaq" v-track-event.click="'顶部导航,点击,我的校招'">我的校招</el-menu-item>
                        <el-menu-item index="/findsalary" v-track-event.click="'顶部导航,点击,查薪资'">查薪资</el-menu-item>
                        <el-menu-item index="/bbs/all/999" v-track-event.click="'顶部导航,点击,论坛'">论坛</el-menu-item>
                    </el-menu>
                </div>
            </el-col>
            <el-col :span="7" v-if="token">
                <div class="ku-navbar-user-infos">
                    <!--<div class="ku-navbar-switch-tab">-->
                    <!--<span class="ku-navbar-work">我要工作</span>-->
                    <!--<span class="ku-navbar-sep">|</span>-->
                    <!--<span class="ku-navbar-study">我要考研</span>-->
                    <!--</div>-->
                    <div
                        :class="
                            count.msgCount > 0 || count.commentMsgCount > 0 || count.likeMsgCount > 0
                                ? 'create-box button active'
                                : 'create-box button'
                        "
                        v-if="!isWrite"
                    >
                        <img class="first-img" @click="goCreate" src="../../assets/icons/deng.svg" alt="" srcset="" />
                        <span @click="goCreate">创作中心</span>
                        <img
                            class="first-img xiaoxi"
                            @click="goMessage"
                            src="../../assets/icons/xiaoxi.svg"
                            alt=""
                            srcset=""
                        />
                    </div>

                    <div class="ku-navbar-users button">
                        <div style="height: 24px" @click="goCenter">
                            <el-avatar :size="24" :src="avatar"></el-avatar>
                        </div>
                        <el-dropdown @command="handleCommand">
                            <div class="ku-navbar-name ellipsis">
                                {{ userInfo.nickName }}
                                <!--<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>-->
                            </div>
                            <el-dropdown-menu slot="dropdown" class="ku-navbar-menu-wraper">
                                <el-dropdown-item :command="{ type: 'router', path: '/info/edit' }">
                                    <div class="ku-navabar-item">
                                        <span class="ku-navbar-text">个人主页</span>
                                    </div>
                                </el-dropdown-item>
                                <!--<el-dropdown-item>-->
                                <!--<div class="ku-navabar-item">-->
                                <!--<span class="ku-navbar-icon ku-navbar-resume"></span>-->
                                <!--<span class="ku-navbar-text">我的简历</span>-->
                                <!--</div>-->
                                <!--</el-dropdown-item>-->
                                <el-dropdown-item
                                    :command="{
                                        type: 'router',
                                        path: '/myfaq',
                                        query: { active: 2, tab: 'delivery', time: new Date().getTime() },
                                    }"
                                >
                                    <div class="ku-navabar-item">
                                        <span class="ku-navbar-text">投递记录</span>
                                    </div>
                                </el-dropdown-item>
                                <el-dropdown-item
                                    :command="{
                                        type: 'router',
                                        path: '/myfaq',
                                        query: { active: 2, tab: 'collection', time: new Date().getTime() },
                                    }"
                                >
                                    <div class="ku-navabar-item" @click="setSelectedTab">
                                        <span class="ku-navbar-text">我的收藏</span>
                                    </div>
                                </el-dropdown-item>
                                <!-- <el-dropdown-item>
                                    <div class="ku-navabar-item">
                                        <span class="ku-navbar-text" @click="logoutHanlder">退出登录</span>
                                    </div>
                                </el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </el-col>
            <el-col :span="5" v-else>
                <div class="ku-navbar-user-infos">
                    <!--<div class="ku-navbar-switch-tab">-->
                    <!--<span class="ku-navbar-work ku-work-study-active">我要工作</span>-->
                    <!--<span class="ku-navbar-sep">|</span>-->
                    <!--<span class="ku-navbar-study">我要考研</span>-->
                    <!--</div>-->
                    <div class="create-box button">
                        <img class="first-img" @click="goCreate" src="../../assets/icons/deng.svg" alt="" srcset="" />
                        <span @click="goCreate">创作中心</span>
                        <img
                            class="first-img xiaoxi"
                            @click="goCreate"
                            src="../../assets/icons/xiaoxi.svg"
                            alt=""
                            srcset=""
                        />
                    </div>
                    <!-- <div class="ku-navbar-users button">
                        <span class="ku-navbar-login-btn" @click="showLoginDialog">登录 | 注册</span>
                    </div> -->
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getUserUnreadMsgNum } from "@/api/user"
export default {
    name: "ku-navbar",
    data() {
        return {
            activeIndex: "1",
            count: {},
        }
    },
    created() {
        this.setCount()
    },
    mounted() {
        this.$nextTick(() => {
            document.removeEventListener("visibilitychange", this.visibilityChangeFun, true)
            document.addEventListener("visibilitychange", this.visibilityChangeFun, { passive: true })
        })
    },
    computed: {
        ...mapGetters(["token", "userInfo", "avatar", "isWrite", "isHaveMassege"]),
        activeMenu() {
            const route = this.$route
            const { meta, path, matched, query } = route
            if (meta.reload) {
                const result = this.$router.resolve({
                    path: "/reload",
                    query: { ...query, redirect: path },
                })
                return result.href
            }
            if (meta.activeMenu) {
                return meta.activeMenu
            }
            if (matched[0]) {
                return matched[0].path
            }
            return path
        },
        isWrite() {
            const route = this.$route
            const { path } = route
            if (path == "/fullwrite") {
                return true
            } else {
                return false
            }
        },
    },
    watch: {
        isHaveMassege: function (newVal, oldVal) {
            if (newVal !== oldVal && newVal) {
                this.setCount()
            }
        },
    },
    methods: {
        setSelectedTab(){
            localStorage.setItem('selectedTab','collection')
        },
        visibilityChangeFun(e = "") {
            if (document.visibilityState === "hidden") {
                // 离开当前tab标签
                console.log("切换tab")
            } else {
                // 回到当前tab
                this.setCount()
            }
        },
        setCount() {
            this.$store.dispatch("setCount", {})
            getUserUnreadMsgNum().then(res => {
                this.count = res.data || {}
                this.$store.dispatch("setCount", this.count)
            })
        },
        goIndex() {
            this.$router.push({
                path: "/ship",
            })
        },
        goCenter() {
            this.$router.push({
                path: "/info/edit",
            })
        },
        goMessage() {
            let { msgCount = 0, commentMsgCount = 0, likeMsgCount = 0 } = this.count
            let current = 1

            if (msgCount) {
                current = 1
            } else if (commentMsgCount) {
                current = 2
            } else if (likeMsgCount) {
                current = 3
            } else {
                current = 1
            }
            this.$router.push({
                path: "/create/index/messagelist",
                query: {
                    current: current,
                },
            })
        },
        goCreate() {
            if (!this.token) {
                this.showLoginDialog()
                return false
            }
            this.$router.push({
                path: "/create",
            })
        },
        goWrite() {
            if (!this.token) {
                this.showLoginDialog()
                return false
            }
            this.$router.push({
                path: "/fullwrite",
                query: {
                    time: new Date().getTime(),
                },
            })
        },
        showLoginDialog() {
            this.$store.commit("SET_SHOWLOGIN", true)
        },
        logoutHanlder() {
            this.$store
                .dispatch("LogOut")
                .then(() => {
                    this.$router.push("/reload")
                    sessionStorage.removeItem("isOpenFaq")
                })
                .then(() => {
                    this.$message({
                        message: "退出登录成功",
                        type: "warning",
                        duration: 1000,
                        offset: "70px",
                    })
                })
        },
        handleCommand(params) {
            const { type, path, query } = params
            if (type == "router") {
                this.$router.push({ path: path, query: query })
            }
        },
    },
}
</script>

<style lang="scss">
.ku-navbar {
    .write-menu {
        height: 60px;
        color: #fff;
        line-height: 60px;
        margin-left: 30px;
        display: flex;
        align-items: center;
        width: 572px;
        span {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            padding-left: 20px;
            position: relative;
            display: flex;
            align-items: center;
            i {
                margin-right: 10px;
                font-style: normal;
                font-size: 18px;
                margin-top: 3px;
            }
        }
        .fir-title {
            cursor: pointer;
            &::before {
                position: absolute;
                top: 50%;
                left: 0;
                content: "";
                width: 1px;
                height: 16px;
                background: #ffffff;
                transform: translateY(-50%);
            }
        }
    }
    .create-box {
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        &.active::before {
            content: "";
            position: absolute;
            right: 30px;
            bottom: 19px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #ff4739;
        }
        span {
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
            margin-right: 20px;
        }
        img {
            display: block;
            width: 24px;
            height: 24px;
            margin-right: 30px;
            &.first-img {
                margin-right: 6px;
            }
            &.xiaoxi {
                margin-right: 30px;
                position: relative;
            }
        }
    }
}
.ku-navbar-menu {
    border: none !important;
    .el-menu-item {
        font-size: 16px;
        font-weight: 500;
        background-color: transparent !important;
        transition: none;
    }
    .is-active {
        position: relative;
        &::after {
            position: absolute;
            content: "";
            background: rgb(36, 198, 139);
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
        }
    }
}
.ku-navbar-menu-wraper {
    width: 120px;
    padding: 14px 0 20px !important;
    .el-dropdown-menu__item {
        padding: 8px 0;
        line-height: 20px !important;
    }
    .el-dropdown-menu__item + .el-dropdown-menu__item {
        margin-top: 2px;
    }
}
</style>

<style lang="scss" scoped>
.ku-navbar {
    .ku-navbar-menus {
        display: flex;
        align-items: center;
        justify-items: stretch;
        justify-content: flex-start;
        height: 64px;
    }

    .ku-navbar-logo {
        width: 172px;
        height: 40px;
        cursor: pointer;
    }

    .ku-navbar-menu {
        margin-left: 18px;
    }

    .ku-navbar-user-infos {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        justify-items: stretch;
        height: 64px;
        float: right;
    }

    .ku-navbar-users {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .ku-navbar-switch-tab {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-right: 40px;
    }

    .ku-navbar-work {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        cursor: pointer;
        padding: 6px 12px;
    }

    .ku-navbar-sep {
        font-size: 20px;
        color: #ffffff;
        padding: 0 2px;
    }

    .ku-navbar-study {
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        line-height: 28px;
        cursor: pointer;
        padding: 6px 12px;
    }

    .ku-work-study-active {
        color: #24c68b;
    }

    .ku-navbar-name {
        font-weight: 400;
        color: #fff;
        margin-left: 8px;
        font-size: 16px;
        max-width: 140px;
    }

    .ku-navbar-login-btn {
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #24c68b;
        cursor: pointer;
    }
}

.ku-navabar-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 32px;
}

.ku-navbar-icon {
    width: 25px;
    height: 26px;
    margin-right: 12px;
    background-repeat: no-repeat;
}

.ku-navbar-text {
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

.ku-navbar-user {
    background-image: url("@/assets/icons/gerenzhuye.png");
}

.ku-navbar-resume {
    background-image: url("@/assets/icons/wodejianli.png");
}

.ku-navbar-record {
    background-image: url("@/assets/icons/toudijilu.png");
}

.ku-navbar-store {
    background-image: url("@/assets/icons/wodeshoucang.png");
}
.ku-navbar-login-btn-logout {
    width: 110px;
    background: #24c68b;
    border-radius: 24px;
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
}
</style>
